import { environment } from '../environments/environment';

export interface Heap {
  load(appId: string): void;
  identify(uid: string): void;
  resetIdentity(): void;
  track(eventName: string, params?: any);
};

declare global {
  interface Window {
    heap: Heap;
  }
}

// script is loaded via gtm
export function loadHeap() {
  if (!environment.production) {
    console.log('[IntegrationsService] loadHeap');
  }

  if (!window.heap) {
    if (!environment.production) {
      console.log('[IntegrationsService] heap not defined');
    }
    return;
  }
  window.heap.load(environment.heap.appId);
}

export function setHeapUser(user: string) {
  if (!environment.production) {
    console.log('[IntegrationsService] setHeapUser');
  }
  if (!window.heap) {
    if (!environment.production) {
      console.log('[IntegrationsService] heap not defined');
    }
    return;
  }
  window.heap.identify(user);
}

export function clearHeapUser() {
  if (!environment.production) {
    console.log('[IntegrationsService] clearHeapUser');
  }
  if (!window.heap) {
    if (!environment.production) {
      console.log('[IntegrationsService] heap not defined');
    }
    return;
  }
  window.heap.resetIdentity();
}

export function trackEvent(eventName: string, params?: any) {
  if (!environment.production) {
    console.log(`[IntegrationsService] ${eventName} ${JSON.stringify(params) ?? ''}`);
  }
  if (!window.heap) {
    return;
  }
  window.heap.track(eventName, params);
}
