import { Injectable } from '@angular/core';
import { loadHeap, setHeapUser, trackEvent } from '../../helpers/heap';
import * as Sentry from '@sentry/angular-ivy';
@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor() {
    this.initializeIntegrations();
  }

  public initializeIntegrations() {
    loadHeap();
    this.trackEvent('init', {
      category: 'system',
      message: 'Started app',
      level: 'info'
    })
  }

  public setUser(email: string) {
    setHeapUser(email);
    Sentry.configureScope((scope => {
      scope.setUser({ 'email': email });
    }));
  }

  public trackEvent(eventName: string, params?: any) {
    trackEvent(eventName, params);
  }
}
